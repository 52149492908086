import create from 'zustand'

import { getSessionState } from '../utils/getSessionState'

export type ButtonSize = 'large' | 'small'

export type BotStatus = 'idle' | 'loaded' | 'initialized' | 'active'

export type ChatbotStore = {
  conversationId: string | undefined
  sessionId: string | undefined
  activityId: string | undefined
  replyToId: string | undefined
  isAdviceLoading: boolean
  status: BotStatus
  minimized: boolean
  buttonSize: ButtonSize
  loaded: boolean
  newMessage: boolean
  buttonText: string | undefined
  hasSession: boolean
  categoryId: number | undefined
  initialMessage: string | undefined
  openChat: () => void
  setButtonSize: (buttonSize: ButtonSize) => void
  setAdviceLoading: (payload: boolean) => void
  setNewMessage: (payload: boolean) => void
  setInitialMessage: (payload: string | undefined) => void
  setStatus: (payload: BotStatus) => void
  setMinimized: (payload: boolean) => void
  setCategoryId: (payload: number | undefined) => void
  setButtonText: (payload: string | undefined) => void
}

export const useChatbotStore = create<ChatbotStore>(set => ({
  conversationId: undefined,
  sessionId: undefined,
  activityId: undefined,
  replyToId: undefined,
  isAdviceLoading: false,
  status: 'idle',
  minimized: true,
  buttonSize: 'large',
  loaded: false,
  newMessage: false,
  buttonText: '',
  hasSession: !!getSessionState(),
  categoryId: undefined,
  initialMessage: undefined,
  setButtonSize: (buttonSize: ButtonSize) => {
    set(state => ({
      ...state,
      buttonSize,
    }))
  },
  setMinimized: minimized => {
    set(state => ({
      ...state,
      minimized,
    }))
  },
  openChat: () => {
    set(state => ({
      ...state,
      buttonSize: 'small',
      minimized: false,
      loaded: true,
      newMessage: false,
    }))
  },
  setAdviceLoading: isAdviceLoading => {
    set(state => ({
      ...state,
      isAdviceLoading,
    }))
  },
  setNewMessage: newMessage => {
    set(state => ({
      ...state,
      newMessage,
    }))
  },
  setInitialMessage: initialMessage => {
    set(state => ({
      ...state,
      initialMessage,
    }))
  },
  setStatus: status => {
    set(state => ({
      ...state,
      status,
    }))
  },
  setCategoryId: categoryId => {
    set(state => ({
      ...state,
      categoryId,
    }))
  },
  setButtonText: buttonText => {
    set(state => ({
      ...state,
      buttonText,
    }))
  },
}))
