/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const Item: msRest.CompositeMapper = {
  serializedName: "Item",
  type: {
    name: "Composite",
    className: "Item",
    modelProperties: {
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      question: {
        required: true,
        serializedName: "question",
        type: {
          name: "String"
        }
      },
      categories: {
        required: true,
        serializedName: "categories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const Category: msRest.CompositeMapper = {
  serializedName: "Category",
  type: {
    name: "Composite",
    className: "Category",
    modelProperties: {
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Number"
        }
      },
      name: {
        required: true,
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      subCategories: {
        required: true,
        serializedName: "subCategories",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      sortIndex: {
        required: true,
        serializedName: "sortIndex",
        type: {
          name: "Number"
        }
      },
      items: {
        required: true,
        serializedName: "items",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Item"
            }
          }
        }
      }
    }
  }
};

export const Intent: msRest.CompositeMapper = {
  serializedName: "Intent",
  type: {
    name: "Composite",
    className: "Intent",
    modelProperties: {
      answers: {
        required: true,
        serializedName: "answers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      suggestions: {
        required: true,
        serializedName: "suggestions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      metadata: {
        required: true,
        serializedName: "metadata",
        type: {
          name: "Object"
        }
      },
      sessionId: {
        required: true,
        serializedName: "sessionId",
        type: {
          name: "String"
        }
      },
      dialogType: {
        required: true,
        serializedName: "dialogType",
        type: {
          name: "Number"
        }
      },
      turnState: {
        required: true,
        serializedName: "turnState",
        type: {
          name: "Object"
        }
      }
    }
  }
};
