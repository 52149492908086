import React from 'react'

export const ChatbotIcon: React.FC<{ size?: number }> = ({ size = 32 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 31">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-1052 -876)">
          <path d="M1069.008 876c7.69.428 13.92 6.658 14.35 14.461l-.003.918a15.128 15.128 0 01-1.624 6.86 15.375 15.375 0 01-13.748 8.508 15.13 15.13 0 01-6.864-1.625 2.05 2.05 0 00-1.572-.115l-7.547 1.539 2.351-6.735a2.05 2.05 0 00-.115-1.573 15.128 15.128 0 01-1.624-6.86 15.375 15.375 0 018.5-13.75 15.137 15.137 0 016.866-1.628zm4.02 14.204h-11.493v3.068h8.269v2.83c0 1.382-.943 2.527-2.544 2.527-1.58 0-2.544-1.145-2.544-2.527h-3.18c0 3.348 2.653 5.595 5.746 5.595 3.07 0 5.746-2.311 5.746-5.595v-5.898zm-5.387-7.9c-2.182 0-3.95 1.607-3.95 3.59 0 1.984 1.768 3.592 3.95 3.592 2.182 0 3.95-1.608 3.95-3.591 0-1.984-1.768-3.592-3.95-3.592z"></path>
        </g>
      </g>
    </svg>
  )
}
