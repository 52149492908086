import styled from '@emotion/styled'
import React, { useCallback } from 'react'

import { ChatbotIconBlue } from './components/icons/ChatbotIconBlue'
import { colors } from './constants/colors'
import { useDcxCategory } from './hooks/useDcxCategory'
import { eventSubject } from './utils/eventEmitter'

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 30px;
`

const Heading = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
`

const Paragraph = styled.p`
  margin: 0;
  margin-left: 15px;
  font-family: inherit;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
  white-space: pre-line;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-auto-rows: 1fr; */
`

const AnswerButton = styled.button`
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  color: ${colors.primary};
  font-size: 16px;
  line-height: 20px;
  padding: 20px;
  width: 100%;
  border: none;
  outline: none;
  border: 2px solid white;
  cursor: pointer;
  text-align: left;
  height: 100%;
  transition: all 200ms;
  margin-bottom: 10px;

  &:hover {
    border-color: ${colors.primary};
  }
`

interface ChatSnippetProps {
  categoryId: string
  snippetText?: string
}

const ChatSnippet: React.FC<ChatSnippetProps> = ({ categoryId, snippetText }) => {
  const { category } = useDcxCategory(categoryId)

  const answerClickHandler = useCallback(answer => {
    eventSubject.next(answer)
  }, [])

  return (
    <Wrapper>
      <Heading>
        <ChatbotIconBlue />
        <Paragraph>
          {snippetText || (
            <>
              Hallo. Ik ben Julo, de digitale assistent van het Juridisch Loket.
              <br />
              Waar wilt u meer over weten?
            </>
          )}
        </Paragraph>
      </Heading>

      <List>
        {category?.items.map((answer: any) => (
          <li key={answer.id}>
            <AnswerButton onClick={() => answerClickHandler(answer.question)}>
              {answer.question}
            </AnswerButton>
          </li>
        ))}
      </List>
    </Wrapper>
  )
}

export default ChatSnippet
