import React from 'react'

export function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="14"
      height="14"
      viewBox="0 0 14 14">
      <defs>
        <path
          id="close-path-1"
          d="M5.293 5.293a1 1 0 011.414 0L12 10.585l5.293-5.292a1 1 0 011.32-.083l.094.083a1 1 0 010 1.414L13.415 12l5.292 5.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0L12 13.415l-5.293 5.292a1 1 0 01-1.32.083l-.094-.083a1 1 0 010-1.414L10.585 12 5.293 6.707a1 1 0 01-.083-1.32z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-5 -5)">
          <mask fill="#fff">
            <use xlinkHref="#close-path-1"></use>
          </mask>
          <use fill="#fff" xlinkHref="#close-path-1"></use>
        </g>
      </g>
    </svg>
  )
}
