/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Mappers from "../models/botControllerMappers";
import * as Parameters from "../models/parameters";
import { JuridischLoketBotserviceContext } from "../juridischLoketBotserviceContext";

/** Class representing a BotController. */
export class BotController {
  private readonly client: JuridischLoketBotserviceContext;

  /**
   * Create a BotController.
   * @param {JuridischLoketBotserviceContext} client Reference to the service client.
   */
  constructor(client: JuridischLoketBotserviceContext) {
    this.client = client;
  }

  /**
   * @param token
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  createDirectLineToken(token: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param token
   * @param callback The callback
   */
  createDirectLineToken(token: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param token
   * @param options The optional parameters
   * @param callback The callback
   */
  createDirectLineToken(token: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  createDirectLineToken(token: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        token,
        options
      },
      createDirectLineTokenOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const createDirectLineTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "directline/token",
  queryParameters: [
    Parameters.token
  ],
  responses: {
    201: {},
    default: {}
  },
  serializer
};
