import { useEffect, useState } from 'react'

import { apiService } from '../services/api.service'

interface State {
  status: 'loading' | 'error' | 'success'
  category: any
}

export const useDcxCategory = (categoryId: string | number | undefined) => {
  const [state, setState] = useState<State>({
    status: 'loading',
    category: undefined,
  })

  useEffect(() => {
    if (categoryId) {
      apiService.digitalcxController.getCategories().then(res => {
        const category = res.find(d => d.id === Number(categoryId))

        if (category) {
          setState({
            status: 'success',
            category,
          })
        } else {
          setState(state => ({
            ...state,
            status: 'error',
          }))
        }
      })
    }
  }, [categoryId])

  return state
}
