import React, { Suspense, useCallback, useEffect, useState } from 'react'
import shallow from 'zustand/shallow'

import { ChatbotButton } from './components/ChatbotButton'
import { gtm } from './services/Gtm'
import { useChatbotStore } from './store/chatbot'
import { eventSubject } from './utils/eventEmitter'

const Chatbot = React.lazy(() => import('./Chatbot'))

const App: React.FC = () => {
  const {
    minimized,
    newMessage,
    buttonSize,
    buttonText,
    hasSession,
    loaded,
    categoryId,
    initialMessage,
  } = useChatbotStore(
    state => ({
      minimized: state.minimized,
      newMessage: state.newMessage,
      buttonSize: state.buttonSize,
      buttonText: state.buttonText,
      hasSession: state.hasSession,
      loaded: state.loaded,
      categoryId: state.categoryId,
      initialMessage: state.initialMessage,
    }),
    shallow,
  )

  const visible = hasSession || categoryId || initialMessage

  const [loading, setLoading] = useState(false)

  const openChat = useChatbotStore(state => state.openChat)
  const setButtonSize = useChatbotStore(state => state.setButtonSize)
  const setInitialMessage = useChatbotStore(state => state.setInitialMessage)
  const setCategoryId = useChatbotStore(state => state.setCategoryId)
  const setButtonText = useChatbotStore(state => state.setButtonText)

  const handleMaximizeButtonClick = useCallback(() => {
    openChat()
    setLoading(true)
    gtm.chatOpened()
  }, [openChat])

  useEffect(
    function messageEventListener() {
      eventSubject.subscribe(answer => {
        setInitialMessage(answer)
        handleMaximizeButtonClick()
      })

      return () => {
        eventSubject.unsubscribe()
      }
    },
    [setInitialMessage, handleMaximizeButtonClick],
  )

  useEffect(() => {
    function turbolinksHandler() {
      const element = document.getElementById('hjl-chatbot-attr')

      if (element) {
        const { initialQuestion, categoryId, buttonText } = element.dataset
        setInitialMessage(initialQuestion)
        setCategoryId(categoryId ? Number(categoryId) : undefined)
        setButtonText(buttonText)
        console.log(element.dataset)
      }
    }

    turbolinksHandler()

    window.addEventListener('turbolinks:load', turbolinksHandler)

    return () => {
      window.removeEventListener('turbolinks:load', turbolinksHandler)
    }
  }, [setInitialMessage, setCategoryId, setButtonText])

  useEffect(() => {
    function handleTriggerEvent(event: Event) {
      const { detail } = event as CustomEvent

      setInitialMessage(detail.initialQuestion)
      setCategoryId(detail.categoryId ? Number(detail.categoryId) : undefined)
      handleMaximizeButtonClick()
    }

    window.addEventListener('trigger::chatbot', handleTriggerEvent)

    return () => {
      window.removeEventListener('trigger::chatbot', handleTriggerEvent)
    }
  }, [setInitialMessage, setCategoryId, handleMaximizeButtonClick])

  const showChatbotButton = loading || (minimized && visible)

  return (
    <>
      {showChatbotButton && (
        <ChatbotButton
          loading={loading}
          onClick={handleMaximizeButtonClick}
          onCollapse={e => {
            e.stopPropagation()
            setButtonSize('small')
          }}
          newMessage={newMessage}
          hasSession={hasSession}
          size={buttonSize}>
          {buttonText || (
            <>
              Hebt u hier nog vragen over? <br />
              Probeer onze digitale assistent
            </>
          )}
        </ChatbotButton>
      )}
      {loaded && (
        <Suspense fallback={<></>}>
          <Chatbot
            onLoad={() => {
              setLoading(false)
            }}
          />
        </Suspense>
      )}
    </>
  )
}

export default App
