export function getSessionState() {
  let sessionState: any = {}

  // get botstate from session
  try {
    const state = JSON.parse(sessionStorage.getItem('botState') || '{}')

    // remove suggestedActions if they exist
    // if (state) {
    //   deleteSuggestionFromSessionState(state)
    // }

    sessionState = state
  } catch (err) {
    /* do nothing if it fails */
    console.error(err)
  }

  return sessionState
}

export function deleteSuggestionFromSessionState(state: any) {
  delete state.suggestedActions
  if (state.activities && state.activities[state.activities.length - 1]) {
    delete state.activities[state.activities.length - 1].suggestedActions
  }
}
