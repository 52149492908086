import { css } from '@emotion/core'
import styled from '@emotion/styled'
import * as React from 'react'

import { ButtonSize } from '../store/chatbot'
import { mq } from '../utils/media'
import { ChatbotIcon } from './icons/ChatbotIcon'
import { Close } from './icons/Close'
import { Loader } from './Loader'
import { StatusIndicator } from './StatusIndicator'

interface ChatbotButtonProps {
  onClick: () => void
  newMessage: boolean
  hasSession: boolean
  size: ButtonSize
  loading: boolean
  onCollapse: (e: any) => void
}

interface CollapsedProps {
  collapsed: boolean
}

const Button = styled.button<CollapsedProps>`
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #0d4acc;
  color: #fff;
  padding: 10px;
  display: flex;
  min-height: 52px;
  appearance: none;
  border: none;
  font-family: inherit;
  font-size: 12px;
  line-height: 1.33;
  align-items: center;
  position: fixed;
  right: 0;
  max-width: calc(100% - 40px);
  bottom: 20px;
  min-width: 52px;
  transform: translateX(-20px);
  transition: transform 0.2s cubic-bezier(0.77, 0, 0.175, 1);
  outline: none;
  cursor: pointer;

  ${mq[2]} {
    transform: translateX(-30px);
    min-height: 60px;
    min-width: 60px;
    font-size: 14px;
    line-height: 1.47;
    padding: 10px 15px;
    bottom: 30px;
  }

  ${props =>
    props.collapsed &&
    css`
      transform: translateX(0);
      height: 52px;
      border-radius: 5px 0 0 5px;
      ${mq[2]} {
        height: 60px;
        transform: translateX(0);
      }
    `}
`

const Collapse = styled.div<CollapsedProps>`
  max-width: 250px;
  opacity: 1;
  transition: width 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.1s,
    opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden;
  align-items: center;
  display: flex;

  ${props =>
    props.collapsed &&
    css`
      opacity: 0;
      width: 0px;
      max-width: 0px;
    `}
`

const Content = styled.div`
  flex-grow: 1;
  text-align: left;
  padding: 0 15px;
  flex-shrink: 0;
  white-space: pre-line;
`

const Minimize = styled.div`
  flex-shrink: 0;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: none;
  appearance: none;
`

const IconWrapper = styled.div`
  position: relative;
`

export const ChatbotButton: React.FC<ChatbotButtonProps> = ({
  onClick,
  size,
  onCollapse,
  hasSession,
  children,
  loading,
}) => {
  const collapsed = React.useMemo(() => size === 'small', [size])

  return (
    <Button onClick={onClick} collapsed={collapsed} aria-label="Digitale assistent">
      <IconWrapper>
        {loading ? (
          <Loader color="white" size={22} padding={0} />
        ) : (
          <>
            <ChatbotIcon />
            {hasSession && <StatusIndicator size={8} />}
          </>
        )}
      </IconWrapper>

      <Collapse collapsed={collapsed}>
        <Content>{children}</Content>

        <Minimize onClick={onCollapse} aria-label="Sluiten">
          <Close />
        </Minimize>
      </Collapse>
    </Button>
  )
}
