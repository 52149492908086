/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "../models";
import * as Mappers from "../models/digitalcxControllerMappers";
import * as Parameters from "../models/parameters";
import { JuridischLoketBotserviceContext } from "../juridischLoketBotserviceContext";

/** Class representing a DigitalcxController. */
export class DigitalcxController {
  private readonly client: JuridischLoketBotserviceContext;

  /**
   * Create a DigitalcxController.
   * @param {JuridischLoketBotserviceContext} client Reference to the service client.
   */
  constructor(client: JuridischLoketBotserviceContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DigitalcxControllerGetDialogsResponse>
   */
  getDialogs(options?: msRest.RequestOptionsBase): Promise<Models.DigitalcxControllerGetDialogsResponse>;
  /**
   * @param callback The callback
   */
  getDialogs(callback: msRest.ServiceCallback<Models.Category[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDialogs(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Category[]>): void;
  getDialogs(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Category[]>, callback?: msRest.ServiceCallback<Models.Category[]>): Promise<Models.DigitalcxControllerGetDialogsResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getDialogsOperationSpec,
      callback) as Promise<Models.DigitalcxControllerGetDialogsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DigitalcxControllerGetCategoriesResponse>
   */
  getCategories(options?: msRest.RequestOptionsBase): Promise<Models.DigitalcxControllerGetCategoriesResponse>;
  /**
   * @param callback The callback
   */
  getCategories(callback: msRest.ServiceCallback<Models.Category[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCategories(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Category[]>): void;
  getCategories(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Category[]>, callback?: msRest.ServiceCallback<Models.Category[]>): Promise<Models.DigitalcxControllerGetCategoriesResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      getCategoriesOperationSpec,
      callback) as Promise<Models.DigitalcxControllerGetCategoriesResponse>;
  }

  /**
   * @param name
   * @param [options] The optional parameters
   * @returns Promise<Models.DigitalcxControllerGetEventResponse>
   */
  getEvent(name: string, options?: msRest.RequestOptionsBase): Promise<Models.DigitalcxControllerGetEventResponse>;
  /**
   * @param name
   * @param callback The callback
   */
  getEvent(name: string, callback: msRest.ServiceCallback<Models.Intent>): void;
  /**
   * @param name
   * @param options The optional parameters
   * @param callback The callback
   */
  getEvent(name: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Intent>): void;
  getEvent(name: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Intent>, callback?: msRest.ServiceCallback<Models.Intent>): Promise<Models.DigitalcxControllerGetEventResponse> {
    return this.client.sendOperationRequest(
      {
        name,
        options
      },
      getEventOperationSpec,
      callback) as Promise<Models.DigitalcxControllerGetEventResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getDialogsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dcx/dialogs",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Category"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getCategoriesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dcx/categories",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Category"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getEventOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "dcx/event",
  queryParameters: [
    Parameters.name
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Intent
    },
    default: {}
  },
  serializer
};
