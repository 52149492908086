import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import ChatSnippet from './ChatSnippet'
import { useChatbotStore } from './store/chatbot'
import { getSessionState } from './utils/getSessionState'

/**
 * Hack to load chunks from current script url
 * https://github.com/webpack/webpack/issues/7077
 */

//@ts-ignore
const url = new URL(document.currentScript.src)
//@ts-ignore
__webpack_public_path__ = url.origin + __webpack_public_path__

function renderChatbot() {
  const element = document.getElementById('hjl-chatbot')

  if (element) {
    const { initialQuestion, categoryId, buttonText } = element.dataset

    const sessionState = getSessionState()

    const hasSession = !!sessionState && sessionState.activities?.length > 0

    useChatbotStore.setState(state => ({
      ...state,
      initialMessage: initialQuestion,
      categoryId: categoryId ? Number(categoryId) : undefined,
      buttonText,
      hasSession,
      buttonSize: hasSession ? 'small' : 'large',
    }))

    ReactDOM.render(<App />, element)
  }
}

function renderSnippet() {
  const snippetElements = document.querySelectorAll<HTMLElement>('[js-hook-chatsnippet]')

  if (snippetElements) {
    const elements = Array.from(snippetElements)

    elements.forEach(element => {
      const { dialogId, categoryId, snippetText } = element.dataset
      ReactDOM.render(
        <ChatSnippet snippetText={snippetText} categoryId={dialogId! || categoryId!} />,
        element,
      )
    })
  }
}

/**
 * for if we decide to use turbolinks on the main website
 */

window.addEventListener('turbolinks:load', () => {
  renderSnippet()
})

renderChatbot()
renderSnippet()
