/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as operations from "./operations";
import { JuridischLoketBotserviceContext } from "./juridischLoketBotserviceContext";

class JuridischLoketBotservice extends JuridischLoketBotserviceContext {
  // Operation groups
  mainController: operations.MainController;
  botController: operations.BotController;
  digitalcxController: operations.DigitalcxController;

  /**
   * Initializes a new instance of the JuridischLoketBotservice class.
   * @param [options] The parameter options
   */
  constructor(options?: Models.JuridischLoketBotserviceOptions) {
    super(options);
    this.mainController = new operations.MainController(this);
    this.botController = new operations.BotController(this);
    this.digitalcxController = new operations.DigitalcxController(this);
  }
}

// Operation Specifications

export {
  JuridischLoketBotservice,
  JuridischLoketBotserviceContext,
  Models as JuridischLoketBotserviceModels,
  Mappers as JuridischLoketBotserviceMappers
};
export * from "./operations";
