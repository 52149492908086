class Gtm {
  private conversationId = ''

  constructor() {
    window.dataLayer = window.dataLayer || []
  }

  setConversationId(id: string) {
    this.conversationId = id
  }

  push(input: any) {
    window.dataLayer.push(input)
  }

  concat(input: any[]) {
    input.forEach(tag => {
      this.push({
        ...tag,
      })
    })
  }

  chatBot(input: any) {
    window.dataLayer.push({
      event: 'chatBot',
      conversationId: this.conversationId,
      chatBot: false,
      ...input,
    })
  }

  chatOpened(input?: any) {
    window.dataLayer.push({
      event: 'chatOpened',
      conversationId: this.conversationId,
      ...input,
    })
  }

  chatClosed(input?: any) {
    window.dataLayer.push({
      event: 'chatClosed',
      conversationId: this.conversationId,
      ...input,
    })
  }

  chatMoreInfo(input: any & { moreInfoTitle: string }) {
    window.dataLayer.push({
      event: 'chatMoreInfo',
      conversationId: this.conversationId,
      ...input,
    })
  }

  chatPrevious(input: any & { question: string }) {
    window.dataLayer.push({
      event: 'chatPrevious',
      conversationId: this.conversationId,
      ...input,
    })
  }

  chatFeedback(input: any & { feedback: string }) {
    window.dataLayer.push({
      event: 'chatFeedback',
      conversationId: this.conversationId,
      ...input,
    })
  }
}

export const gtm = new Gtm()
