import styled from '@emotion/styled'

export const StatusIndicator = styled.div<{ size: number }>`
  background-color: #00c670;
  border: 1px solid white;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
`
