import React from 'react'

export function ChatbotIconBlue() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-690 -449)">
          <g transform="translate(690 450)">
            <g>
              <g>
                <g>
                  <g fill="#0D4ACC">
                    <g>
                      <g>
                        <circle cx="25" cy="25" r="25"></circle>
                      </g>
                    </g>
                  </g>
                  <g fill="#FFF" fillRule="nonzero" transform="translate(15.625 9.375)">
                    <ellipse cx="9.744" cy="5.732" rx="6.305" ry="5.732"></ellipse>
                    <path d="M13.197 17.506v4.517c0 2.206-1.506 4.034-4.06 4.034-2.521 0-4.061-1.828-4.061-4.034H0c0 5.344 4.235 8.93 9.171 8.93 4.9 0 9.171-3.69 9.171-8.93V12.61H0v4.896h13.197z"></path>
                  </g>
                </g>
              </g>
              <circle cx="45" cy="5" r="5.5" fill="#00C670" stroke="#FFF"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
