/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Mappers from "../models/mainControllerMappers";
import { JuridischLoketBotserviceContext } from "../juridischLoketBotserviceContext";

/** Class representing a MainController. */
export class MainController {
  private readonly client: JuridischLoketBotserviceContext;

  /**
   * Create a MainController.
   * @param {JuridischLoketBotserviceContext} client Reference to the service client.
   */
  constructor(client: JuridischLoketBotserviceContext) {
    this.client = client;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  status(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  status(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  status(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  status(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.client.sendOperationRequest(
      {
        options
      },
      statusOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const statusOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  responses: {
    200: {},
    default: {}
  },
  serializer
};
