/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const name: msRest.OperationQueryParameter = {
  parameterPath: "name",
  mapper: {
    required: true,
    serializedName: "name",
    type: {
      name: "String"
    }
  }
};
export const token: msRest.OperationQueryParameter = {
  parameterPath: "token",
  mapper: {
    required: true,
    serializedName: "token",
    type: {
      name: "String"
    }
  }
};
