import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

import { colors } from '../constants/colors'

const offset = 187

const rotator = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
`

const dash = keyframes`
    0% { stroke-dashoffset: ${offset}; }
    50% {
        stroke-dashoffset: ${offset / 4};
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: ${offset};
        transform:rotate(450deg);
    }
`

const LoaderWrapper = styled.div<{ color: string; padding: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding}px;

  svg {
    animation: ${rotator} 1.4s linear infinite;
  }

  circle {
    stroke-dasharray: ${offset};
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: ${dash} 1.4s ease-in-out infinite;
    stroke: ${props => props.color};
  }
`

export const Loader = ({ color = colors.primary, size = 50, padding = 15 }) => {
  return (
    <LoaderWrapper color={color} padding={padding}>
      <svg width={size} height={size} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </LoaderWrapper>
  )
}
